import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { debounce } from '../../Helpers/debounce';
import MainMenu from '../../Components/MainMenu';
import MessageSelectedContainer from '../MessageSelectedContainer';
import RightDrawerMessage from '../../Components/RightDrawerMessage';
import {
  archiveMessages,
  checkMessage,
  clearCheckedMessages,
  clearMessagePagination,
  clearMessages,
  clearSelectedMessage,
  deleteMessages,
  getMessages,
  getMoreMessages,
  getPendingReplies,
  lastSevenDaysMessages,
  unArchiveMessages,
} from '../../Actions/messagesActions';
import { clearMessage, getMessage, getTimeZone, initDraftMessage, setLockedByMember, updateDraftMessage } from '../../Actions/messageActions';
import {
  changeMessagesFilters,
  changeMessagesSearchTerm,
  changeMessagesSort,
  resetMessagesFilters,
  toggleMessagesFilters,
} from '../../Actions/messagesFiltersActions';
import { getNotificationsMessages } from '../../Actions/notificationsActions';
import snackBarStatus from '../../Actions/snackbarActions';
import ChangeMainTabs from '../../Actions/mainTabsActions';
import { openCustomModal } from '../../Actions/customModalActions';
import { clearSelectedGroup } from '../../Actions/groupsActions';
import LoaderOverlay from '../../Components/LoaderOverlay';
import SearchFiltersPad from '../../Components/SearchFiltersPad';
import MessagesFilters from '../../Components/MessagesFilters';
import MessageSearchDesktop from '../../Components/MessageSearchDesktop';
import { MessagesList } from '../../Components/MessagesList';
import MessagesNotifications from '../../Components/MessagesNotifications';
import { navigationControllerRedirect } from '../../Actions/navigationControllerActions';
import './index.sass';
import AppBarMobile from '../AppBarMobile';
import { camelCaseObjects } from '../../Helpers/Utility';
import { MESSAGE_COMPOSER_TYPE, MESSAGE_DESIGNER_TYPE } from '../../Config/Constants';
import MessageModalManager, { MESSAGE_MODAL_MANAGER_VIEWS } from '../MessageModalManager';
import Button from '@material-ui/core/Button';

/*eslint-disable*/
class Messages extends Component {

  debouncedSearch = null;

  constructor(props) {
    super(props);
    this.debouncedSearch = debounce(this.handleSearchTermChange, 800);

    this.state = {
      isSearchOpen: false
    };
  }

  UNSAFE_componentWillMount() {
    this.props.clearSelectedMessage();
    this.props.ChangeMainTabs({ payload: { value: 1 } });
    this.props.clearSelectedGroup();
    this.props.getNotificationsMessages();
  }

  // TODO: unify
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.organizationId && !nextProps.isNotificationsLoaded && !nextProps.isNotificationsLoading) {
      this.props.getNotificationsMessages();
    }
  }

  componentDidMount() {
    const { toggleMessagesFilters, changeMessagesFilters, location } = this.props;

    const messagesInitialFilter = location.state ? location.state.filters : null;
    if (messagesInitialFilter) {
      // If type filter passed
      toggleMessagesFilters(true); // Open filter
      changeMessagesFilters({ type: messagesInitialFilter }); // Set filter state
      this.handleFiltersChange({ type: messagesInitialFilter }); // Trigger api call
    } else {
      this.loadMessages(this.resetPagination());
    }

    this.props.getPendingReplies();
    this.props.lastSevenDaysMessages();
  }

  componentWillUnmount() {
    this.props.clearCheckedMessages();
    this.props.resetMessagesFilters();
    this.props.clearMessages();
    this.props.snackBarStatus({ payload: { enable: false } });
    localStorage.removeItem('statusDesignerRedirect');
  }

  resetPagination = () => {
    this.props.clearMessagePagination(); // TODO: remove(?)
    return 1;
  };

  handleSearchTermChange = value => {
    this.props.changeMessagesSearchTerm(value).then(() => {
      this.loadMessages(this.resetPagination())
    });
  };

  handleFiltersChange = filters => {
    this.props.changeMessagesFilters(filters).then(() => {
      this.loadMessages(this.resetPagination());
    });
  };

  handleToggleMessagesFilters = (val, isExpanded) => {
    this.props.toggleMessagesFilters(val)
    this.setState({ isSearchOpen: isExpanded })
  };

  handleSort = value => {
    this.props.changeMessagesSort(value)
      .then(() => this.loadMessages(this.resetPagination()));
  };

  handleSelectEvaCardMessage = async messagePublicId => {
    if (this.props.isMessageLoading) {
      return;
    }

    this.props.clearMessage();

    const messageLite = this.props.messages.find(m => m.publicId === messagePublicId);
    const response = await this.props.getMessage(messagePublicId);
    const lockedBy = response.data?.locked_by;

    if (messageLite.status === 'draft') {
      switch (messageLite.type) {
        case MESSAGE_DESIGNER_TYPE:
        case MESSAGE_COMPOSER_TYPE:
          if (lockedBy?.public_id && lockedBy.public_id !== this.props.userProfile.public_id) {
            this.props.setLockedByMember(lockedBy);
            this.props.openCustomModal({ content: <MessageModalManager view={MESSAGE_MODAL_MANAGER_VIEWS.LOCKED_BY_MEMBER} /> });
          } else {
            if (messageLite.type === MESSAGE_COMPOSER_TYPE) {
              const message = camelCaseObjects(response.data);
              this.props.initDraftMessage(message);
              this.props.navigationControllerRedirect(`/messages`);
              this.props.updateDraftMessage(messageLite.publicId);
              this.props.openCustomModal({ content: <MessageModalManager view={MESSAGE_MODAL_MANAGER_VIEWS.EDITOR}/> });
            } else {
              this.props.navigationControllerRedirect(`/messages/edit/${messagePublicId}`);
            }
          }
          break;
      }
    } else {
      this.props.navigationControllerRedirect(`/messages/${messagePublicId}`);
    }
  };

  handleCheckItem = publicId => this.props.checkMessage(publicId);

  handleNewMessage = () =>
    this.props.openCustomModal({ content: <MessageModalManager view={MESSAGE_MODAL_MANAGER_VIEWS.EDITOR}/> });

  renderView = ({ style, ...props }) => {
    const viewStyle = {
      backgroundColor: '#4c4c4c',
      color: '#4c4c4c',
    };
    return <div className="box" style={{ ...style, ...viewStyle }} {...props} />;
  };

  loadMessages = nextPage => {
    const { messagesPagination: { currentPage }, filters: { type = 'message', date }, sort, searchTerm } = this.props;
    const pagination = nextPage ? nextPage : currentPage;
    this.props.getMessages(
      type,
      searchTerm,
      sort,
      date,
      pagination,
    );
  };

  handleScrollFrame = () => {
    if (!this.props.isMessagesLoading && !this.props.isLoadingMoreMessages) {
      this.props.getMoreMessages();
    }
  };

  messageActionCallback = () => {
    this.props.navigationControllerRedirect('/messages');
    this.loadMessages(this.resetPagination());
  }

  handleDelete = publicIds => {
    this.props.deleteMessages(publicIds).then(this.messageActionCallback)
  };

  handleArchive = publicIds => {
    this.props.archiveMessages(publicIds).then(this.messageActionCallback)
  };

  handleUnArchive = publicIds => {
    this.props.unArchiveMessages(publicIds).then(this.messageActionCallback)
  };

  render() {
    const {
      isMessagesLoading,
      messages,
      isFiltersOpen,
      filters,
      messagesSevenDays,
      messagesListSelected,
      selectedMessage,
      replyNotificationsCounter,
    } = this.props;
    const WindowHeight = window.innerHeight - 140;
    const { messageId } = this.props.match.params;

    const isAnyFilterSelected = !!(filters.type || filters.date.from || filters.date.to);

    return (
      <MainMenu
        value={1}
        content={
          <React.Fragment>
            <div className="fixed-wrapper">
              <div className={`layout-list ${messageId ? 'message-active' : 'messages-list-active'}`}
                   id="global-messages-container">
                <section className="layout-list-left" id="messages-list-wrapper">
                  <Scrollbars
                    renderThumbVertical={this.renderView}
                    onUpdate={({ top }) => {
                      if (top > 0.99 && !isMessagesLoading) {
                        this.handleScrollFrame();
                      }
                    }}
                    autoHeight
                    autoHide
                    autoHeightMin={WindowHeight}
                    autoHeightMax={WindowHeight}
                    className="custom-scrollbar"
                  >

                    <div className="filters-column">
                      <SearchFiltersPad
                        isFiltersSelected={isAnyFilterSelected}
                        title="Messages"
                        onChange={this.debouncedSearch}
                        onToggle={this.handleToggleMessagesFilters}
                      />

                      <MessagesFilters
                        filters={filters}
                        isOpen={isFiltersOpen}
                        onFiltersChange={this.handleFiltersChange}
                        onSelectionErase={this.props.clearCheckedMessages}
                      />

                      {(!isFiltersOpen && !this.state.isSearchOpen && replyNotificationsCounter !== 0 && replyNotificationsCounter) && (
                        <div className="button-wrapper">
                          <Button
                            className="tip-action tip-action-tap button-eva"
                            onClick={() => this.handleFiltersChange({ type: 'pending' })}
                          >
                            {`${replyNotificationsCounter} new message${replyNotificationsCounter === 1 ? '' : 's'} to review`}
                          </Button>
                        </div>
                      )}

                      <MessageSearchDesktop
                        onSort={this.handleSort}
                        messages={messages}
                        onArchive={this.handleArchive}
                        onDelete={this.handleDelete}
                        onUnArchive={this.handleUnArchive}
                        updateMessageList={this.loadMessages}
                      />

                      <MessagesList
                        messages={this.props.messages}
                        timezone={this.props.organization.time_zone}
                        selectedMessagePublicId={selectedMessage ? selectedMessage.publicId : null}
                        checkedMessages={messagesListSelected}
                        onSelect={this.handleSelectEvaCardMessage}
                        onCheck={this.handleCheckItem}
                      />

                      {isMessagesLoading && <LoaderOverlay/>}
                    </div>
                  </Scrollbars>
                </section>

                <section className="layout-list-right" id="message-details-wrapper">
                  <Scrollbars
                    autoHeight
                    autoHide
                    autoHeightMin={WindowHeight}
                    autoHeightMax={WindowHeight}
                  >
                    <React.Fragment>
                      <Switch>
                        <Route exact path="/messages">
                          <MessagesNotifications
                            isMessagesSevenDays={messagesSevenDays}
                            counter={replyNotificationsCounter}
                            onNewMessage={this.handleNewMessage}
                          />
                        </Route>
                        <Route exact path="/messages/:messageId">
                          <AppBarMobile
                            type="page"
                            title="Messages"
                            onBackClick={() => this.props.navigationControllerRedirect('/messages')}
                          />
                          <MessageSelectedContainer
                            messageId={messageId}
                            onArchive={this.handleArchive}
                            onUnArchive={this.handleUnArchive}
                            onDelete={this.handleDelete}
                          />
                        </Route>
                      </Switch>
                    </React.Fragment>
                  </Scrollbars>
                </section>

              </div>
              <RightDrawerMessage/>
            </div>
          </React.Fragment>
        }
      />
    );
  }
}

const mD = {
  getMessage,
  getMessages,
  getMoreMessages,
  archiveMessages,
  unArchiveMessages,
  deleteMessages,
  checkMessage,
  clearSelectedMessage,
  clearCheckedMessages,
  snackBarStatus,
  ChangeMainTabs,
  getTimeZone,
  lastSevenDaysMessages,
  getPendingReplies,
  openCustomModal,
  initDraftMessage,
  clearMessage,
  clearSelectedGroup,
  clearMessagePagination,
  toggleMessagesFilters,
  changeMessagesSearchTerm,
  changeMessagesFilters,
  resetMessagesFilters,
  changeMessagesSort,
  clearMessages,
  getNotificationsMessages,
  navigationControllerRedirect,
  setLockedByMember,
  updateDraftMessage,
};
/* eslint-disable */
const mS = state => ({
  messages: state.messagesReducer.messages,
  isMessageLoading: state.messageReducer.isMessageLoading,
  isMessagesLoading: state.messagesReducer.isMessagesLoading,
  isLoadingMoreMessages: state.messagesReducer.isLoadingMoreMessages,
  messagesListSelected: state.messagesReducer.messagesListSelected,
  selectedMessage: state.messagesReducer.selectedMessage,
  messagesSevenDays: state.messagesReducer.messagesSevenDays,
  organization: state.organizationReducer.organization,
  organizationId: state.organizationReducer.organization.public_id,
  replyNotificationsCounter: state.messagesReducer.replyNotificationsCounter,
  userProfile: state.userProfileReducer.member,
  role: state.userProfileReducer.main_role,
  messagesPagination: state.messagesReducer.messagesPagination,
  isFiltersOpen: state.messagesFiltersReducer.isFiltersOpen,
  filters: state.messagesFiltersReducer.filters,
  searchTerm: state.messagesFiltersReducer.searchTerm,
  sort: state.messagesFiltersReducer.sort,
  isNotificationsLoaded: state.notificationsReducer.isLoaded,
  isNotificationsLoading: state.notificationsReducer.isLoading,
});
/* eslint-enable */
export default withRouter(connect(mS, mD)(Messages));
