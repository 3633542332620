import { ACTIONS } from '../Actions/groupsActions';
import { ACTIONS as MPA_GROUP_ACTIONS } from '../Actions/myParishAppActions';
import { DEFAULT_RSS_OPTIONS } from '../Config/Constants';

const initialState = {
  code: '',
  default_time_zone: 'EST',
  description: '',
  element: null,
  error: '',
  filterGroupSelected: '',
  forward_reply_email: false,
  forward_reply_sms_number: false,
  globalGroups: {
    myGroups: [],
    availableGroups: [],
  },
  groupSettings: {},
  groups: [],
  groupsSuggestions: [],
  keyword: '',
  listSubGroups: false,
  loadingGroups: false,
  isLoadingGroupSettings: false,
  name: '',
  organizations: [],
  pagination: {},
  privacyLevel: '',
  privacySelected: [],
  replySelected: false,
  searchedGroups: [],
  selectedGroup: null,
  selectedSubGroup: '',
  sendEmail: '',
  sendSms: '',
  shareSelected: [],
  shortName: '',
  senderName: '',
  parentGroup: null,
  showSettingsSearch: false,
  periodTypeSelected: 'month',
  subGroups: [],
  type: 'group',
  middleMenuStatus: false,
  validateKeyword: null,
  keywordError: 'default',
  keywordStatusRequest: false,
  middleMenuLeft: {
    dinamicGrid: 7,
    dinamicGridMiddleMenu: 12,
    dinamicRightContent: 5,
    status: false,
  },
  groupsActivity: {},
  membersStatistics: {},
  unsubscribesStatistics: {},
  statisticsGroups: {},
  statisticsMetrics: {},
  statusButton1: false,
  statusButton2: false,
  stateButtons: {
    button1: false,
    button2: false,
  },
  transitions: {
    transition1: false,
    transition2: false,
    transition3: false,
    transition4: false,
  },
  smsPreferences: 0,
  emailPreferences: 0,
  validationKeywordResponse: true,
  mpaLoggedGroups: null,
  isLoadingNewGroups: false,
  isLoadingMoreGroups: false,
};

const removeMemberFromGroup = (group, membersPublicIds) => ({
  ...group,
  members: group.members.filter(m => !membersPublicIds.includes(m.member.public_id)),
  members_quantity: membersPublicIds.length < group.members_quantity ? group.members_quantity - membersPublicIds.length : 0,
});

const groupsReducer = (state = initialState, action) => {
  const {
    name,
    shortName,
    senderName,
    description,
    privacyLevel,
    error,
    keyword,
    selectedGroup,
    selectedSubGroup,
    sendSms,
    sendEmail,
    parentGroup,
  } = initialState;
  const initialStateSubGroup = {
    ...state,
    name,
    shortName,
    senderName,
    description,
    privacyLevel,
    error,
    keyword,
    sendSms,
    sendEmail,
    selectedSubGroup,
    parentGroup,
  };
  switch (action.type) {
    case ACTIONS.ADD:
      return { ...state, groups: [action.payload, ...state.groups] };
    case ACTIONS.ADD_SUBGROUP:
      return {
        ...initialStateSubGroup,
        subGroups: [action.payload, ...state.subGroups],
      };
    case ACTIONS.CLEARFORM:
      return {
        ...state,
        name,
        shortName,
        senderName,
        description,
        privacyLevel,
        error,
        keyword,
        selectedGroup,
        selectedSubGroup,
        sendSms,
        sendEmail,
        parentGroup,
        forward_reply_email: false,
        forward_reply_sms_number: false,
      };
    case ACTIONS.CLEAR_ACTUAL_GROUPS:
      return { ...state, groups: [] };
    case ACTIONS.CLEAR_FORM_GROUP:
      return { ...initialStateSubGroup };
    case ACTIONS.CLEAR_SEARCHED_GROUPS:
      return { ...state, searchedGroups: [] };
    case ACTIONS.UPDATE_SUBGROUP_AND_GROUP:
      return {
        ...state,
        subGroups: [action.payload, ...state.subGroups],
      };
    case ACTIONS.ERROR:
      return { ...state, error: action.payload };
    case ACTIONS.GET_GROUPS:
      return { ...state, groupsSuggestions: action.payload };
    case ACTIONS.LOADING_GROUPS:
      return { ...state, loadingGroups: action.payload.loadingGroups };
    case ACTIONS.REMOVE_MEMBER:
      return { ...state };
    case ACTIONS.SEARCH_MESSAGES:
      return { ...state, searchedGroups: action.payload[0] };
    case ACTIONS.SELECT:
      return { 
        ...state, 
        selectedGroup: { 
          ...state.selectedGroup,
          ...action.payload 
        } };
    case ACTIONS.SHOW_MEMBERS_SETTINGS_SEARCH:
      return { ...state, ...action.payload };
    case ACTIONS.SUCCESS:
      return { ...state, error: action.payload };
    case ACTIONS.UPDATE:
      return { ...state, ...action.payload };
    case ACTIONS.UPDATE_ALL:
      return { ...state, globalGroups: { ...action.payload } };

    case ACTIONS.UPDATE_GROUP_FROM_LIST: {
      let oldParentGroupId = null;
      let newParentGroupId = null;

      let newGroupsList = state.groups.map(item => {
        if (item.public_id === action.payload.public_id) {
          oldParentGroupId = item.group_parent ? item.group_parent.public_id : null;
          newParentGroupId = action.payload.group_parent ? action.payload.group_parent.public_id : null;

          return {
            ...item,
            ...action.payload,
          };
        }
        return { ...item };
      });

      // Update subgroups quantity if parentGroup was changed
      if (oldParentGroupId !== newParentGroupId) {
        newGroupsList = newGroupsList.map(item => {
          let newQuantity = item.sub_groups_quantity;
          if (item.public_id === oldParentGroupId) {
            newQuantity = newQuantity - 1;
          } else if (item.public_id === newParentGroupId) {
            newQuantity = newQuantity + 1;
          }

          return {
            ...item,
            sub_groups_quantity: newQuantity,
          }
        });
      }

      return {
        ...state,
        groups: newGroupsList,
      };
    }
    case ACTIONS.UPDATE_MEMBER_IN_GROUP:
      return {
        ...state,
        groups: state.groups.map(item => {
          if (item.public_id === action.payload.public_id) {
            return {
              ...item,
              members_quantity: action.payload.members_quantity,
            };
          }
          return { ...item };
        }),
        selectedGroup: {
          ...state.selectedGroup,
          members_quantity: action.payload.members_quantity,
        },
      };
    case ACTIONS.UPDATE_PAGINATED:
      return {
        ...state,
        groups: action.payload.pagination.previousPage
          ? [...state.groups, ...action.payload.groups]
          : action.payload.groups,
        pagination: action.payload.pagination,
      };

    case ACTIONS.LOAD_NEW_GROUPS: {
      const { groups, pagination } = action.payload;
      return {
        ...state,
        groups,
        pagination,
      };
    }

    case ACTIONS.LOAD_MORE_GROUPS: {
      const { groups, pagination } = action.payload;
      return {
        ...state,
        groups: state.groups.concat(groups),
        pagination,
      };
    }

    case ACTIONS.INIT_SELECTED_GROUP:
      return {
        ...state,
        selectedGroup: {
          public_id: null,
          name: '',
          shortName: '',
          senderName: '',
          description: '',
          keyword: '',
          parentGroup: null,
          type: 'group',
          privacy_level: 'open',
          is_general_org_group: false,
          forward_reply_email: false,
          forward_reply_sms_number: false,
          number_of_messages_displayed: DEFAULT_RSS_OPTIONS,
        }
      }

    case ACTIONS.UPDATE_SELECTED_GROUP:
      return {
        ...state,
        selectedGroup: { ...state.selectedGroup, ...action.payload },
      };

    case ACTIONS.UPDATE_MEMBER_IN_SUBGROUP:
      return {
        ...state,
        subGroups: state.subGroups.map(item => {
          if (item.public_id === action.payload.public_id) {
            return {
              ...item,
              members_quantity: action.payload.members_quantity,
            };
          }
          return { ...item };
        }),
        selectedSubGroup: {
          ...state.selectedSubGroup,
          members_quantity: action.payload.members_quantity,
        },
      };
    case ACTIONS.MIDDLE_MENU_STATUS:
      return { ...state, middleMenuStatus: action.payload };
    case ACTIONS.SET_KEYWORD_VALIDATE:
      return { ...state, validateKeyword: action.payload };
    case ACTIONS.SET_KEYWORD_ERROR:
      return { ...state, keywordError: action.payload };
    case ACTIONS.KEYWORD_STATUS_REQUEST:
      return { ...state, keywordStatusRequest: action.payload };
    case ACTIONS.SET_AVAILABLE_KEYWORD:
      return { ...state, validationKeywordResponse: action.payload };
    case ACTIONS.MIDDLE_MENU_LEFT:
      return { ...state, middleMenuLeft: action.payload };
    case ACTIONS.GET_ACTIVITY_GROUPS:
      return { ...state, groupsActivity: action.payload };
    // case ACTIONS.GET_STATISTICS:
    //   return { ...state, statisticsGroups: action.payload };
    case ACTIONS.GET_STATISTICS_METRICS:
      return { ...state, statisticsMetrics: action.payload };
    case ACTIONS.CHANGE_STATUS:
      return { ...state, ...action.payload };
    case ACTIONS.REMOVE_MEMBER_ORGANIZATION:
      return { ...state, ...action.payload };
    case MPA_GROUP_ACTIONS.MPA_GET_LOGIN: {
      const groupIdLoggedIn = action.payload;
      return {
        ...state,
        mpaLoggedGroups: groupIdLoggedIn,
      };
    }
    case ACTIONS.CLEAR_SELECTED_GROUP: {
      return {
        ...state,
        selectedGroup: null,
      };
    }
    case ACTIONS.LOADING_NEW_GROUPS: {
      return {
        ...state,
        isLoadingNewGroups: action.payload,
      };
    }

    case ACTIONS.LOADING_MORE_GROUPS: {
      return {
        ...state,
        isLoadingMoreGroups: action.payload,
      };
    }

    case ACTIONS.UPDATE_NOTIFICATION_PREFERENCES: {
      const { groupId, options } = action.payload;

      return {
        ...state,
        globalGroups: {
          ...state.globalGroups,
          myGroups: state.globalGroups.myGroups.map(g => ({
            ...g,
            groups: g.groups
              .map(group => group.group.public_id === groupId ? ({
                  ...group,
                  group_notification_preference: {
                    ...group.group_notification_preference,
                    ...options,
                  }
                }) : group
              )
          }))

        }
      }
    }

    case ACTIONS.UPDATE_ORG_NOTIFICATION_PREFERENCES: {
      const { groupId, options } = action.payload;
      return {
        ...state,
        globalGroups: {
          ...state.globalGroups,
          myGroups: state.globalGroups.myGroups.map(g =>
            g.all_member_group_id === groupId ?
              ({
                ...g,
                all_member_group_notification_preference: {
                  ...g.all_member_group_notification_preference,
                  ...options
                }
              }) : g
          )
        }
      }
    }

    case ACTIONS.REMOVE_SELECTED_GROUP_MEMBERS: {
      const { publicId, membersPublicIds } = action.payload;

      const parentGroup = state.groups.find(g => g.public_id === publicId);

      let groupsToUpdate = [];

      if (parentGroup) {
        const updGroup = removeMemberFromGroup(parentGroup, membersPublicIds);
        groupsToUpdate = state.groups.map(g => g.public_id === updGroup.public_id ? updGroup : g);
      } else {
        groupsToUpdate = [...state.groups];
        groupsToUpdate.forEach((g) => {
          const grToChange = g.subGroups.find(sg => sg.public_id === publicId);
          if (grToChange) {
            const updGroup = removeMemberFromGroup(grToChange, membersPublicIds);
            g.subGroups = g.subGroups.map(sg => sg.public_id === updGroup.public_id ? updGroup : sg);
          }
        })
      }

      return {
        ...state,
        groups: groupsToUpdate,
        selectedGroup: state.selectedGroup ? removeMemberFromGroup(state.selectedGroup, membersPublicIds) : state.selectedGroup,
      }
    }

    case ACTIONS.REMOVE_ALL_GROUP_MEMBERS: {
      return {
        ...state,
        groups: state.groups.map(g => g.public_id === action.payload ? {...g, members_quantity: 0, members: []} : g),
        selectedGroup: {
          ...state.selectedGroup,
          members_quantity: 0,
          members: [],
        }
      }
    }

    case ACTIONS.REMOVE_FB_CONNECTIONS: {
      return {
        ...state,
        groups: state.groups.map(g => ({
          ...g,
          post_facebook: false,
          facebook_pages: [],
        })),
        selectedGroup: {
          ...state.selectedGroup,
          post_facebook: false,
          facebook_pages: [],
        }
      }
    }

    case ACTIONS.INCREASE_MEMBERS_COUNTERS: {
      const { groupsPublicIds, count } = action.payload;

      return {
        ...state,
        groups: state.groups.map(group =>
          groupsPublicIds.includes(group.public_id) ?
            { ...group, members_quantity: group.members_quantity + count } : group)
      }
    }

    case ACTIONS.LOADING_SETTINGS:
      return {
        ...state,
        isLoadingGroupSettings: action.payload,
      }

    case ACTIONS.UPDATE_GROUP_STATUS:
      return {
        ...state,
        groups: state.groups.map(g => g.public_id === state.selectedGroup.public_id ? ({
          ...g,
          isEnabled: action.payload.isEnabled,
        }) : g),
        selectedGroup: {
          ...state.selectedGroup,
          isEnabled: action.payload.isEnabled,
        }
      }

    case ACTIONS.UPDATE_GROUP_MEMBER_PREFERENCE:
      return {
        ...state,
        groups: state.groups.map(g => g.public_id === state.selectedGroup.public_id ? ({
          ...g,
          memberPreference: action.payload,
        }) : g),
        selectedGroup: {
          ...state.selectedGroup,
          memberPreference: action.payload,
        }
      }

    case ACTIONS.REMOVE_GROUP:
      return {
        ...state,
        groups: state.groups.filter(g => g.public_id !== action.payload)
      }

    default:
      return state;
  }
};

export default groupsReducer;
