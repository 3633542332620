import { getGroupsFromDictionary } from '../Groups';

export const getSelectedLongestGroupName = (dictionary = [], groupsIds = []) => {
  const groups = getGroupsFromDictionary(groupsIds, dictionary);

  if (!Array.isArray(groups)) {
    console.error('getGroupsFromDictionary must return an array. Received:', groups);
    return '[Group name]';
  }

  const shortNames = groups
    .map(g => g?.group?.short_name)
    .filter(Boolean);

  return shortNames.length > 0 
    ? shortNames.reduce((a, b) => (a.length >= b.length ? a : b)) 
    : '[Group name]';
};
