import queryString from 'query-string';

import AXIOS from '../Config/AxiosConfig';
import headers from './Helper';
import API_URL from '../Config/env';

const baseURL = '/eva/organization';
const v2baseURL = '/v2/eva/organization';
const baseURL1 = '/eva/group';

const Groups = {
  create(data, orgId) {
    const {
      name,
      description,
      shortName,
      privacy_level,
      groups,
      forward_reply_email,
      forward_reply_sms_number,
      keyword,
      senderName,
    } = data;

    let url = `${baseURL}/${orgId}/group`;
    if (groups && groups.public_id) {
      url = `${baseURL}/${orgId}/group/${groups.public_id}/sub-group`;
    }

    return AXIOS.post(
      url,
      {
        name,
        short_name: shortName,
        privacy_level,
        description,
        forward_reply_email,
        forward_reply_sms_number,
        senderName,
        keyword,
      },
      {
        headers: headers(),
      },
    );
  },
  get(currentOrganizationId, page) {
    const params = {};

    if (page) {
      params.page = page;
    }

    params.all = true;

    const query = queryString.stringify(params);

    return AXIOS.get(`${v2baseURL}/${currentOrganizationId}/groups/search?${query}`, {
      headers: headers(),
    });
  },
  getAllGroups(currentOrganizationId, withAllGroupElem) {
    const allGroupElem = withAllGroupElem ? 'allGrp=true' : '';
    return AXIOS.get(`${v2baseURL}/${currentOrganizationId}/groups/search?${allGroupElem}&all=true`, {
      headers: headers(),
    });
  },

  getGroupsDictionary(org, value, role) {
    const getAll =
      role === 'ROLE_ORGANIZATION_ADMIN' || role === 'ROLE_SUPER_ADMIN' ? 'all=true' : '';
    const term = value ? `term=${value}` : '';
    // TODO: replace 'messages' with 'lite'.
    return AXIOS.get(`${v2baseURL}/${org}/groups/search?${term}${term && getAll ? '&' : ''}${getAll}&messages=true&isEnabled=true`, {
      headers: headers(),
    });
  },

  allGroupsGet() {
    return AXIOS.get(`${baseURL}/groups/search?allGrp=true&all=true&templates=true`, {
      headers: headers(),
    });
  },
  getAll() {
    return AXIOS.get('/eva/member/my-groups', {
      headers: headers(),
    });
  },
  getAllMyProfile() {
    return AXIOS.get('/eva/member/my-groups/by-organizations', { headers: headers() });
  },
  getOrg() {
    return AXIOS.get('/eva/member/all/organizations', {
      headers: headers(),
    });
  },
  updateGroup(data, orgId, groupId) {
    const {
      name,
      description,
      short_name,
      sender_name,
      privacy_level,
      group_parent,
      facebook_pages,
      post_facebook,
      post_twitter,
      post_website,
      send_to_my_parish_app,
      forward_reply_sms_number,
      forward_reply_email,
      online_registration,
      short_url,
      custom_welcome_message,
      bulletin_message = '',
      number_of_messages_displayed,
      keyword,
    } = data;
    /* eslint-disable */
    let groupParent = null;
    if (typeof group_parent === 'string') {
      groupParent = group_parent;
    } else {
      groupParent = group_parent ? group_parent.public_id : null;
    }
    /* eslint-enable */
    return AXIOS.put(
      `${baseURL}/${orgId}/group/${groupId}`,
      {
        name,
        short_name,
        sender_name,
        privacy_level,
        description,
        default_time_zone: 'EST',
        group_parent: groupParent,
        facebook_pages,
        post_facebook,
        post_twitter,
        post_website,
        send_to_my_parish_app,
        forward_reply_sms_number,
        forward_reply_email,
        online_registration,
        short_url,
        custom_welcome_message,
        bulletin_message,
        number_of_messages_displayed,
        keyword,
      },
      {
        headers: headers(),
      },
    );
  },

  removeGroup(groupId) {
    return AXIOS.delete(`/eva/group/${groupId}`, {
      headers: headers(),
    });
  },

  removeMember(data) {
    const { groupId, memberId } = data;
    return AXIOS.delete(`/eva/groups/${groupId}/member/${memberId}`, {
      headers: headers(),
    });
  },

  removeMemberOrganization(data) {
    const { organizationIdRemove, publicId } = data;
    const token = localStorage.getItem('sso_token');
    return AXIOS.post(
      `/eva/organization/${organizationIdRemove}/member/${publicId}/remove-from-org`,
      {
        token,
      },
      {
        headers: headers(),
      },
    );
  },

  getPrivacyPolicy(groupId) {
    return AXIOS.get(`/public/eva/group/${groupId}/privacy`, {
      headers: headers(),
    });
  },
  acceptPrivacyPolicyOfAGroup(data) {
    const { groupId, privacy } = data;
    return AXIOS.put(
      `/eva/group/${groupId}/privacy`,
      {
        privacyLevel: privacy,
      },
      {
        headers: headers(),
      },
    );
  },
  searchGroups(requestData) {
    const { dataSearch, currentOrganizationId, role } = requestData;
    const { searchTerm, sort, activity, privacyLevel, shareOptions, groupType, reply, page } = dataSearch;
    const getAll =
      role === 'ROLE_ORGANIZATION_ADMIN' || role === 'ROLE_SUPER_ADMIN' ? '&all=true' : '';
    const entireUrl = `${v2baseURL}/${currentOrganizationId}/groups/search?`;
    let queryParams = [];

    if (searchTerm && searchTerm !== '') queryParams.push(`term=${encodeURIComponent(searchTerm)}`);

    if (sort && sort !== 'recent' && sort !== 'activity') {
      queryParams.push(`sort=name&sortOrder=${sort}`);
    } else if (sort && (sort === 'recent' || sort === 'activity')) {
      queryParams.push('sortOrder=activity');
    }

    if (activity) {
      const activityArray = activity
        .map(v => `activity[]=${v}`)
        .toString()
        .replace(/,/g, '&');
      queryParams.push(activityArray);
    }

    if (privacyLevel) {
      const groupArray = privacyLevel
        .map(v => `privacyLevel[]=${v}`)
        .toString()
        .replace(/,/g, '&');
      queryParams.push(groupArray);
    }

    if (shareOptions) {
      const groupArray = shareOptions
        .map(v => `shareOptions[]=${v}`)
        .toString()
        .replace(/,/g, '&');
      queryParams.push(groupArray);
    }

    if (groupType) {
      const groupArray = groupType
        .map(v => `type=${v}`)
        .toString()
        .replace(/,/g, '&');
      queryParams.push(groupArray);
    }

    if (reply) {
      const replyMessages = `replyMessages=${reply}`;
      queryParams.push(replyMessages);
    }

    if (page) {
      const pagination = `page=${page}&limit=10`;
      queryParams.push(pagination);
    } else {
      const pagination = 'messages=true';
      queryParams.push(pagination);
    }

    queryParams = queryParams
      .filter(v => !!v)
      .toString()
      .replace(/,/g, '&');

    return AXIOS.get(`${entireUrl}${queryParams}${getAll}`, {
      headers: headers(),
    });
  },
  toggleGroupActivation(group, operation) {
    return AXIOS.put(
      `${baseURL1}/${group}/status-operation/${operation}`,
      {},
      {
        headers: headers(),
      },
    );
  },
  updateMemberPreference(groupPublicId, preference) {
    return AXIOS.put(
      `${baseURL1}/${groupPublicId}/member-preference`,
      { preference },
      {
        headers: headers(),
      },
    );
  },
  existsUrlShortener(url) {
    return AXIOS.get(`/eva/url-shorterner/${url}`, {
      headers: headers(),
    });
  },
  // getStatistics(groupId) {
  //   return AXIOS.get(`eva/metrics/group/${groupId}/activity`, {
  //     headers: headers(),
  //   });
  // },
  getStatisticsMetrics(filterParameters) {
    const { periodTypeSelected, groupId, datesFilter } = filterParameters;
    const entireUrl = `/eva/metrics/group/${groupId}/health?`;
    let queryParams = [];

    if (periodTypeSelected) {
      queryParams.push(`periodType=${periodTypeSelected}`);
    }

    if (datesFilter) {
      const { startDate, endDate } = datesFilter;
      queryParams.push(`startDate=${startDate}&endDate=${endDate}`);
    }

    queryParams = queryParams
      .filter(v => !!v)
      .toString()
      .replace(/,/g, '&');

    return AXIOS.get(`${entireUrl}${queryParams}`, {
      headers: headers(),
    });
  },
  getGroupsActivity(org, role) {
    const getAll =
      role === 'ROLE_ORGANIZATION_ADMIN' || role === 'ROLE_SUPER_ADMIN' ? '?all=true' : '';
    return AXIOS.get(`${v2baseURL}/${org}/groups-stats${getAll}`, {
      headers: headers(),
    });
  },
  getNotificationPreferences(groups) {
    return AXIOS.post('/eva/members/notification-preferences', { groups }, { headers: headers() });
  },
  sendWelcomeMessageByEmail(groupId) {
    return AXIOS.post(`/eva/group/${groupId}/send-welcome-message-by-email`, {}, { headers: headers() });
  },
  sendWelcomeMessageByText(groupId) {
    return AXIOS.post(`/eva/group/${groupId}/send-welcome-message-by-sms`, {}, { headers: headers() });
  },
  getExportedMembersFile(publicId) {
    return AXIOS.get(`/eva/groups/${publicId}/members/export`, { headers: headers() });
  },
  getUnsubscribeMembersFile(publicId) {
    return AXIOS.get(`/eva/groups/${publicId}/export-removed-members`, { headers: headers() });
  },
  getOrgAdmins(id) {
    const promiseVal = AXIOS.get(`/v2/eva/groups/${id}/admins`,{
        headers: headers(),
      },
    );
    promiseVal.catch(err => {
      if (Axios.isCancel(err)) {
        return false;
      }
      throw err;
    });
    return promiseVal;
  },
  updateOrgAdminReplyNotification(groupId, adminId, isReplyNotification) {
    if (isReplyNotification) {
      return AXIOS.put(
        `/admin-panel/group/${groupId}/reply-notification-off`,
        {"admins":[adminId]},
        {headers: headers()},
      );
    } else {
      return AXIOS.put(
        `/admin-panel/group/${groupId}/reply-notification-on`,
        {"admins":[adminId]},
        {headers: headers()},
      );
    }
  },

  getGroupSettings(publicId) {
    return AXIOS.get(`/eva/groups/${publicId}/settings`, { headers: headers() });
  },
};

export default Groups;
